// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import {getRemoteConfig} from 'firebase/remote-config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpQp0anvI3oJmGhuB_Q_9mPekEOVsqSnI",
  authDomain: "unikrish-dev.firebaseapp.com",
  projectId: "unikrish-dev",
  storageBucket: "unikrish-dev.appspot.com",
  messagingSenderId: "1085966983099",
  appId: "1:1085966983099:web:342f918c6c571e2c4cae9e"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const remoteConfig = getRemoteConfig(app)

const awaitFirebaseInitialization = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe()
      resolve(userAuth)
    }, reject)

  })
}

export {auth, awaitFirebaseInitialization, remoteConfig}