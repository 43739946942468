import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  SelectChangeEvent,
} from '@mui/material';
import { ButtonTextSizeEnum, ButtonVariantEnum } from '../../../components/Button/button.types';
import ButtonLocal from '../../../components/Button/Button';
import { api } from '../../../services';
import { buttonActions, statusColors, Video } from './constants';
import styles from './videoListStyles.module.scss';
import { auth } from '../../../config/firebase';
import { v4 as uuidv4 } from 'uuid';

interface ApiResponse<T> {
  response: {
    mediaList: T[];
    totalPages: number;
  };
}

interface VideoUrlResponse {
  response: {
    url: string;
  };
}

const fetchWrapper = async <T,>(
    url: string,
    customHeaders: Record<string, string> = {}
): Promise<T> => {
  const currentUser = auth.currentUser;
  const clientId = process.env.REACT_APP_CLIENT_ID ?? 'default_client_id';
  const requestId = uuidv4();

  if (!currentUser) {
    throw new Error("User not authenticated");
  }

  const accessToken = await currentUser.getIdToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ClientId: clientId,
    RequestId: requestId,
    ...customHeaders,
  };

  const response = await fetch(url, { headers });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json() as Promise<T>;
};

const fetchVideoURLWrapper = async (url: string, customHeaders: Record<string, string> = {}) => {
  const currentUser = auth.currentUser;
  const clientId = process.env.REACT_APP_CLIENT_ID ?? 'default_client_id';
  const requestId = uuidv4();

  if (currentUser) {
    const access_token = await currentUser.getIdToken();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      ClientId: clientId,
      RequestId: requestId,
      ...customHeaders,
    };

    return fetch(url, { headers })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.blob().then((blob) => ({
            blob,
            statusText: response.statusText,
          }));
        });
  } else {
    throw new Error("User not authenticated");
  }
};

const VideosList: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [openStatusModal, setOpenStatusModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  useEffect(() => {
    void fetchVideos();
  }, [page]);

  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [videoUrl]);

  const fetchVideos = async (): Promise<void> => {
    setLoading(true);
    try {
      const gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
      if (!gatewayUrl) {
        throw new Error('Gateway URL is not defined');
      }

      const url = `${gatewayUrl}/admin/media/list/status/UN_VERIFIED/page/${page}`;
      const response = await fetchWrapper<ApiResponse<Video>>(url);

      setVideos(response.response.mediaList);
      setTotalPages(response.response.totalPages);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleBulkAction = (action: typeof buttonActions[keyof typeof buttonActions]): void => {
    // Implement bulk action logic here
    console.log('Bulk action:', action, 'for videos:', selected);
  };

  const fetchVideoUrl = async (videoId: string): Promise<string> => {
    try {
      const gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
      if (!gatewayUrl) {
        throw new Error('Gateway URL is not defined');
      }

      const url = `${gatewayUrl}/admin/media/stream/media/${videoId}`;
      const { blob } = await fetchVideoURLWrapper(url);

      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching video URL:', error);
      throw error;
    }
  };

  const handleVideoClick = async (video: Video): Promise<void> => {
    setSelectedVideo(video);
    setLoading(true);
    try {
      const url = await fetchVideoUrl(video.id);
      setVideoUrl(url);
    } catch (error) {
      console.error('Error fetching video URL:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClosePopup = (): void => {
    setSelectedVideo(null);
    if (videoUrl) {
      URL.revokeObjectURL(videoUrl);
    }
    setVideoUrl('');
  };

  const handleOpenStatusModal = (video: Video): void => {
    setSelectedVideo(video);
    setStatus(video.status);
    setReason('');
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = (): void => {
    setOpenStatusModal(false);
    setSelectedVideo(null);
    setStatus('');
    setReason('');
  };

  const handleChangeStatus = async (): Promise<void> => {
    if (!selectedVideo || !auth.currentUser) return;

    const reviewRequest = {
      id: selectedVideo.id,
      reviewedBy: auth.currentUser.uid,
      status,
      reason,
    };

    try {
      await api.post('/admin/media/review', reviewRequest);
      void fetchVideos();
    } catch (error) {
      console.error('Error updating video status:', error);
    } finally {
      handleCloseStatusModal();
    }
  };

  const handleStatusChange = (event: SelectChangeEvent<string>): void => {
    setStatus(event.target.value);
  };

  return (
      <div className={styles.videosListContainer}>
        <div className={styles.header}>
          <ButtonLocal
              variant={ButtonVariantEnum.primary}
              onClick={() => handleBulkAction(buttonActions.approved)}
              size={ButtonTextSizeEnum.sm}
              text="Approve"
          />
          <ButtonLocal
              variant={ButtonVariantEnum.primary}
              onClick={() => handleBulkAction(buttonActions.rejected)}
              size={ButtonTextSizeEnum.sm}
              text="Reject"
          />
        </div>

        {loading ? (
            <div className={styles.loading}>
              <CircularProgress />
            </div>
        ) : (
            <>
              <Grid container spacing={2}>
                {videos.map((video) => (
                    <Grid item xs={12} sm={6} md={4} key={video.id}>
                      <Paper elevation={3} className={styles.videoCard}>
                        <div className={styles.videoContent} onClick={() => void handleVideoClick(video)}>
                          {video.thumbnail && (
                              <img
                                  src={`data:image/png;base64, ${video.thumbnail}`}
                                  alt="Video thumbnail"
                                  className={styles.thumbnail}
                              />
                          )}
                          <div className={styles.videoDetails}>
                            <div className={styles.videoName}>{video.name}</div>
                            <div className={styles[statusColors[video.status]]}>{video.status}</div>
                          </div>
                        </div>
                        <Button
                            onClick={() => handleOpenStatusModal(video)}
                            className={styles.changeStatusButton}
                        >
                          Change Status
                        </Button>
                      </Paper>
                    </Grid>
                ))}
              </Grid>

              <div className={styles.pagination}>
                <Button
                    disabled={page === 0}
                    onClick={() => setPage(page - 1)}
                    className={styles.paginationButton}
                >
                  Previous
                </Button>
                <span>{page + 1} of {totalPages}</span>
                <Button
                    disabled={page === totalPages - 1}
                    onClick={() => setPage(page + 1)}
                    className={styles.paginationButton}
                >
                  Next
                </Button>
              </div>
            </>
        )}

        {/* Video Playback Dialog */}
        <Dialog
            open={!!selectedVideo && !openStatusModal}
            onClose={handleClosePopup}
            maxWidth="md"
            fullWidth
        >
          <DialogTitle>{selectedVideo?.name}</DialogTitle>
          <DialogContent className={styles.dialogContent}>
            {loading ? (
                <CircularProgress />
            ) : videoUrl ? (
                <video controls className={styles.videoPlayer}>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
            ) : (
                <div>Failed to load video</div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} className={styles.closeButton}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Status Change Dialog */}
        <Dialog
            open={openStatusModal}
            onClose={handleCloseStatusModal}
            maxWidth="sm"
            fullWidth
        >
          <DialogTitle>Change Status for {selectedVideo?.name}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                  value={status}
                  onChange={handleStatusChange}
                  label="Status"
              >
                <MenuItem value="VERIFIED">VERIFIED</MenuItem>
                <MenuItem value="UN_VERIFIED">UNVERIFIED</MenuItem>
                <MenuItem value="REJECTED">REJECTED</MenuItem>
              </Select>
            </FormControl>
            <TextField
                fullWidth
                margin="normal"
                label="Reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                multiline
                rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStatusModal} className={styles.closeButton}>
              Cancel
            </Button>
            <Button onClick={() => void handleChangeStatus()} className={styles.okButton}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

export default VideosList;